<template>
  <div>
    <template v-if="!loading">
      <v-row
        v-if="$vuetify.breakpoint.mdAndUp"
        class="px-6 cw-price-cards--desktop"
      >
        <v-col
          v-for="(product, i) in products"
          :key="i"
          cols="6"
          class="pr-4"
        >
          <cw-product-card
            :product="product"
            :programs="programs"
            class="px-2"
          />
        </v-col>
      </v-row>

      <template v-else>
        <v-row justify="center" class="cw-price-cards--mobile">
          <v-col class="px-2" cols="auto">
            <v-btn
              id="cw-price-cards__left"
              :disabled="activeStep === 0"
              fab
              small
              class="mobile-fab"
              @click="
                activeStep = 0;
                $eventLogger.clickEvent($event);
              "
            >
              <v-icon class="white--text">
                keyboard_arrow_left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="px-2" cols="auto">
            <v-btn
              id="cw-price-cards__right"
              :disabled="activeStep === 1"
              fab
              small
              class="mobile-fab"
              @click="
                activeStep = 1;
                $eventLogger.clickEvent($event);
              "
            >
              <v-icon class="white--text">
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div id="products_window">
          <div
            id="products_window__track"
            v-touch="{
              left: () => swipe('Left'),
              right: () => swipe('Right'),
            }"
            :class="activeStep === 0 ? 'beginning' : 'end'"
            class="pt-4"
          >
            <cw-product-card
              v-for="(product, i) in products"
              :key="i"
              :product="product"
              :programs="programs"
              class="px-2"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { getBrandPrices } from '@shared/store/utils/api';
import { programs } from '@shared/config';

export default {
  name: 'CwProductCards',

  components: {
    'cw-product-card': () => import('@shared/components/ProductCard'),
  },

  data: () => ({
    loading: false,
    activeStep: 0,
    products: [],
    programs,
  }),

  computed: {
    /**
     * Attempts to read brand name from URL params.
     *
     * If found, returns that brand. If not found,
     * returns Saldo.com as a default brand.
     *
     * @returns String - brandName
     */
    brandName() {
      const defaultBrand = 'Saldo.com';
      const routerBrand = this.$route.query.brand;

      if (!routerBrand) return defaultBrand;

      const brandFound = Object.values(this.programs)
        .some(p => p.brandName === routerBrand);

      if (!brandFound) return defaultBrand;

      return routerBrand;
    },
  },

  created() {
    this.initProducts();
  },

  methods: {
    async initProducts() {
      const allowedProductTypes = [
        'continuousLoan',
        'instalmentLoan',
      ];

      this.loading = true;

      const brandPrices = await getBrandPrices(this.brandName);

      this.products = brandPrices.data.data
        .filter(item => allowedProductTypes.includes(item.type));

      this.products
        .forEach((brand) => {
          const defaultAmount = brand.type === 'continuousLoan' ? 100000 : 300000;

          brand.amount = brand.prices.find(price => price.amount === defaultAmount); // eslint-disable-line
          brand.instalmentAmount = 0; // eslint-disable-line
        });

      this.$dataLayer.PushToECommerce(
        {
          params: {
            action: 'detail',
            formData: { products: this.products },
          },
        },
      );

      this.loading = false;
    },

    swipe(direction) {
      this.activeStep = direction === 'Right' ? 0 : 1;
    },
  },
};
</script>

<style lang="scss">
.mobile-fab {
  background-color: #7a37d2 !important;
}

.cw-product-card {
  --primary: #fff;
  --secondary: #7a37d2;
  --tertiary: #282828;
  --body-bgcolor: var(--primary);
  --header-bgcolor: #fff;
  --icon-color: var(--primary);

  &--v-continuous,
  &--v-instalment {
    --primary: #7a37d2;
  }

  &--v-instalment,
  &--s-instalment,
  &--s-car-instalment {
    --body-bgcolor: #fff;
    --header-bgcolor: var(--primary);
    --icon-color: #fff;
  }

  &--l-continuous {
    --primary: #7a37d2;
  }

  &--s-continuous,
  &--s-instalment,
  &--s-car-instalment {
    --primary: #7a37d2;
  }

  border-radius: 8px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &:hover {
    box-shadow:
      0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }

  .v-list__tile__action {
    min-width: 42px;
  }

  .v-input-group__input {
    padding-right: 8px !important;
  }

  &__icon {
    padding: 16px;

    .v-icon {
      color: var(--icon-color);
    }
  }

  &__header {
    background-color: var(--header-bgcolor);
    color: var(--icon-color);
  }

  &__body {
    background-color: var(--body-bgcolor);
    color: var(--header-bgcolor);
    margin-bottom: -4px;
    position: relative;

    .slant {
      background-color: var(--body-bgcolor);
      height: 48px;
      left: -30px;
      margin-bottom: 2em;
      position: absolute;
      top: -20px;
      transform: rotate(2deg);
      width: 130%;

      @include mq($until: sm) {
        height: 40px;
      }
    }

    p {
      font-size: 13px;
      font-weight: 700;
      position: relative;
      text-align: center;
    }
  }

  &__instalment_amount {
    .v-btn {
      border-radius: 0;
    }
  }

  &__amount {
    max-width: 300px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
  }

  &__value-propositions {
    i {
      color: var(--icon-color) !important;
      margin-top: -6px;
    }
  }

  &__logo {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    margin: 32px 0;

    @include mq($from: md) {
      margin: 48px 0;
    }

    img {
      height: 22px;

      @include mq($from: md) {
        height: 32px;
      }
    }
  }
}

#products_window {
  overflow-x: hidden;
  width: 100%;
  display: block;
  &__track {
    display: grid;
    grid-template-columns: calc(100vw - 48px) calc(100vw - 48px);
    grid-gap: 4px;
    margin-left: 12px;
    margin-right: 12px;
    transition: all 0.5s ease-in-out;
  }

  &__track > div {
    z-index: 1;
  }

  &__track.beginning {
    margin-right: calc(72px - 100vw);
  }

  &__track.end {
    margin-left: calc(72px - 100vw);
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 16px 134px;
  border-radius: 8px;
}
</style>
